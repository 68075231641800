import { IconProps } from "./Icon.interface";

const CheckListIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    fill="currentColor"
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="surface1">
      <path d="M 8 4 L 13.332031 4 C 13.734375 4 14 3.734375 14 3.332031 C 14 2.933594 13.734375 2.667969 13.332031 2.667969 L 8 2.667969 C 7.601562 2.667969 7.332031 2.933594 7.332031 3.332031 C 7.332031 3.734375 7.601562 4 8 4 Z M 8 4 " />
      <path d="M 13.332031 7.332031 L 8 7.332031 C 7.601562 7.332031 7.332031 7.601562 7.332031 8 C 7.332031 8.398438 7.601562 8.667969 8 8.667969 L 13.332031 8.667969 C 13.734375 8.667969 14 8.398438 14 8 C 14 7.601562 13.734375 7.332031 13.332031 7.332031 Z M 13.332031 7.332031 " />
      <path d="M 13.332031 12 L 8 12 C 7.601562 12 7.332031 12.265625 7.332031 12.667969 C 7.332031 13.066406 7.601562 13.332031 8 13.332031 L 13.332031 13.332031 C 13.734375 13.332031 14 13.066406 14 12.667969 C 14 12.265625 13.734375 12 13.332031 12 Z M 13.332031 12 " />
      <path d="M 4.800781 2.132812 L 3.667969 3.800781 L 3.332031 3.464844 C 3.066406 3.199219 2.667969 3.199219 2.398438 3.464844 C 2.132812 3.734375 2.132812 4.132812 2.398438 4.398438 L 3.265625 5.332031 C 3.398438 5.464844 3.535156 5.535156 3.734375 5.535156 C 3.734375 5.535156 3.800781 5.535156 3.800781 5.535156 C 4 5.535156 4.199219 5.398438 4.265625 5.265625 L 5.867188 2.867188 C 6.066406 2.535156 6 2.132812 5.734375 1.933594 C 5.398438 1.734375 5 1.800781 4.800781 2.132812 Z M 4.800781 2.132812 " />
      <path d="M 4.800781 6.800781 L 3.667969 8.464844 L 3.332031 8.132812 C 3.066406 7.867188 2.667969 7.867188 2.398438 8.132812 C 2.132812 8.398438 2.132812 8.800781 2.398438 9.066406 L 3.265625 10 C 3.398438 10.132812 3.535156 10.199219 3.734375 10.199219 C 3.734375 10.199219 3.800781 10.199219 3.800781 10.199219 C 4 10.199219 4.199219 10.066406 4.265625 9.933594 L 5.867188 7.535156 C 6.066406 7.199219 6 6.800781 5.667969 6.601562 C 5.398438 6.398438 5 6.464844 4.800781 6.800781 Z M 4.800781 6.800781 " />
      <path d="M 4.800781 11.464844 L 3.667969 13.132812 L 3.332031 12.800781 C 3.066406 12.535156 2.667969 12.535156 2.398438 12.800781 C 2.132812 13.066406 2.132812 13.464844 2.398438 13.734375 L 3.265625 14.667969 C 3.398438 14.800781 3.535156 14.867188 3.734375 14.867188 C 3.734375 14.867188 3.800781 14.867188 3.800781 14.867188 C 4 14.867188 4.199219 14.734375 4.265625 14.601562 L 5.867188 12.199219 C 6.066406 11.867188 6 11.464844 5.667969 11.265625 C 5.398438 11.066406 5 11.132812 4.800781 11.464844 Z M 4.800781 11.464844 " />
    </g>
  </svg>
);

export default CheckListIcon;
