import { IconProps } from "components/icons/Icon.interface";

const UserShieldIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5575 6.7775L12.6775 5.6525C12.575 5.6125 12.3625 5.56 12.1225 5.6525L9.2425 6.7775C8.975 6.8825 8.8 7.1275 8.8 7.4C8.8 10.19 10.5175 12.12 12.1225 12.7475C12.3625 12.84 12.5725 12.7875 12.6775 12.7475C13.96 12.2475 16 10.5125 16 7.4C16 7.1275 15.825 6.8825 15.5575 6.7775ZM12.4 11.56V6.8325L14.7875 7.765C14.6475 9.9425 13.265 11.15 12.4 11.56ZM8.905 11.6H1.2V10.96C1.2 9.77 2.17 8.8 3.36 8.8C3.725 8.8 4.3175 9.2 5.6 9.2C6.7775 9.2 7.3725 8.8625 7.735 8.8125C7.6625 8.4325 7.62 8.0375 7.6075 7.625C6.9675 7.705 6.62 8 5.5975 8C4.42 8 4.0775 7.6 3.3575 7.6C1.505 7.6 0 9.105 0 10.96V11.6C0 12.2625 0.5375 12.8 1.2 12.8H9.9825C9.595 12.4575 9.2275 12.0575 8.905 11.6ZM5.6 7.2C7.5875 7.2 9.2 5.5875 9.2 3.6C9.2 1.6125 7.5875 0 5.6 0C3.6125 0 2 1.6125 2 3.6C2 5.5875 3.6125 7.2 5.6 7.2ZM5.6 1.2C6.9225 1.2 8 2.2775 8 3.6C8 4.9225 6.9225 6 5.6 6C4.2775 6 3.2 4.9225 3.2 3.6C3.2 2.2775 4.2775 1.2 5.6 1.2Z"
      fill="currentColor"
    />
  </svg>
);

export default UserShieldIcon;
