import { IconProps } from "./Icon.interface";

const IntegrationsIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1662 8.75978C12.0462 8.83694 11.8651 8.95336 11.8017 8.96197C11.1332 8.95942 11.8227 6.0775 11.9632 5.49769C11.1406 5.65594 8.2285 6.22928 7.52061 5.69342C7.53325 5.18292 8.44444 4.7375 8.44444 3.54411C8.44444 2.28394 7.27739 1.5 6.02761 1.5C4.75408 1.5 3.11111 2.21544 3.11111 3.58508C3.11111 4.72067 3.90839 5.38069 3.99983 5.65617C3.97931 6.45722 0.778139 5.70483 0 5.50425V15.2697C0.290667 15.3448 1.5745 15.7222 2.88889 15.7222C4.14722 15.7222 5.33333 15.2851 5.33333 14.0318C5.33333 13.1493 4.44444 12.7636 4.44444 11.9809C4.44444 11.522 5.25653 11.2291 5.81558 11.2291C6.40239 11.2291 7.11111 11.5462 7.11111 11.9399C7.11111 12.6071 6.22222 12.9644 6.22222 14.1045C6.22222 15.9611 8.48764 15.7176 9.95667 15.5274C10.9855 15.3941 10.8778 15.4189 11.9864 15.4189C11.9086 15.1702 11.2069 13.1654 11.6005 12.63C11.8788 12.2511 12.6941 13.5 13.9348 13.5C15.2421 13.5 16 12.0691 16 10.8333C16 9.22797 14.7229 7.11628 12.1662 8.75978ZM13.9348 12.1667C13.2541 12.1667 12.8933 11.2566 11.7372 11.2566C10.757 11.2566 9.87272 12.0106 10.253 14.1463C9.72436 14.2084 7.91272 14.5053 7.57717 14.2099C7.26758 13.9381 8.44444 13.1496 8.44444 11.9399C8.44444 10.6531 7.07014 9.89581 5.81558 9.89581C4.51875 9.89581 3.11111 10.6574 3.11111 11.9809C3.11111 13.3923 4.2615 13.9363 3.93336 14.1907C3.46528 14.5536 1.93742 14.333 1.33333 14.2167V7.18289C3.10928 7.487 5.33333 7.44206 5.33333 5.64303C5.33333 4.75964 4.44444 4.3715 4.44444 3.58508C4.44444 3.12467 5.53717 2.83333 6.02761 2.83333C6.4775 2.83333 7.11111 3.05383 7.11111 3.54411C7.11111 4.19756 6.18711 4.5675 6.18711 5.70867C6.18711 7.38633 8.60092 7.34236 10.2568 7.14422C10.0737 8.63628 10.3031 10.2953 11.8038 10.2953C12.6711 10.2953 13.0577 9.5 13.8932 9.5C14.3724 9.5 14.6667 10.2766 14.6667 10.8333C14.6667 11.4335 14.329 12.1667 13.9348 12.1667Z"
      fill="currentColor"
    />
  </svg>
);

export default IntegrationsIcon;
