import { IconProps } from "components/icons/Icon.interface";

const CheckIcon = ({ className, color }: IconProps) => (
  <svg
    className={className}
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7645 0.109828L4.54442 8.32995L1.52405 5.30957C1.37761 5.16314 1.14017 5.16314 0.993703 5.30957L0.109828 6.19345C-0.0366094 6.33989 -0.0366094 6.57732 0.109828 6.72379L4.27923 10.8932C4.42567 11.0396 4.66311 11.0396 4.80958 10.8932L14.1787 1.52405C14.3252 1.37761 14.3252 1.14017 14.1787 0.993702L13.2949 0.109828C13.1484 -0.0366093 12.911 -0.0366093 12.7645 0.109828Z"
      fill={color || "currentColor"}
    />
  </svg>
);

export default CheckIcon;
