import { IconProps } from "./Icon.interface";

const AssetIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6667 2.66602H3.33333C2.59333 2.66602 2 3.26602 2 3.99935V11.9993C2 12.7327 2.59333 13.3327 3.33333 13.3327H12.6667C13.4 13.3327 14 12.7327 14 11.9993V3.99935C14 3.26602 13.4067 2.66602 12.6667 2.66602ZM12.6667 11.9993H3.33333V5.33268H12.6667V11.9993Z"
      fill="#686E73"
    />
  </svg>
);

export default AssetIcon;
