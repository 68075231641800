import { IconProps } from "./Icon.interface";

const Classification = ({ className }: IconProps) => (
  <svg className={className} viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m983.33 200.02c-83.34 0-233.33-20.004-383.33-140.02-120 120-280 140.02-373.33 140.02-46.668 0-76.668-5.0156-76.668-5.0156v257.7c0 540 450 687.3 450 687.3s450-147.3 450-687.3v-257.7s-24.996 5.0156-66.672 5.0156zm-439.57 579.98-191.26-202.5 56.258-67.5 135 95.629 281.24-208.13 50.629 45z"
      fill="#686E73"
    />
  </svg>
);

export default Classification;
