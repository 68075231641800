import { IconProps } from "./Icon.interface";

const ConnectIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.81943 6.18027C11.416 7.77686 11.5187 10.3142 10.1241 12.029C9.92906 12.2706 10.0371 12.1498 7.46975 14.7171C5.7605 16.4264 2.99268 16.4279 1.28256 14.7171C-0.42716 13.0081 -0.427879 10.2404 1.28256 8.52996C2.30671 7.5058 2.9024 6.90811 3.26409 6.54693C3.49731 6.31405 3.89943 6.47336 3.90428 6.80293C3.91009 7.20122 3.95983 7.59766 4.05259 7.98505C4.08278 8.11096 4.04409 8.24346 3.95253 8.33499L2.51975 9.76746C1.49615 10.7917 1.49203 12.4516 2.51975 13.48C3.54396 14.5036 5.20387 14.5077 6.23225 13.48L8.58193 11.13C9.60437 10.1073 9.60621 8.44205 8.58193 7.41777C8.37444 7.2099 8.13342 7.03845 7.869 6.91061C7.72453 6.84083 7.64456 6.68405 7.66396 6.5248C7.70634 6.17724 7.86071 5.8409 8.12693 5.57465L8.26365 5.43793C8.37693 5.32461 8.55043 5.29436 8.69365 5.36624C9.09987 5.57011 9.48075 5.84158 9.81943 6.18027ZM14.7174 1.28215C13.0073 -0.428636 10.2395 -0.427104 8.53024 1.28215C5.96287 3.84952 6.07093 3.72871 5.87587 3.97027C4.48128 5.68505 4.58396 8.22243 6.18056 9.81902C6.51925 10.1577 6.90012 10.4292 7.30634 10.6331C7.44953 10.7049 7.62303 10.6746 7.73634 10.5614L7.87306 10.4246C8.13931 10.1584 8.29365 9.82205 8.33603 9.47449C8.35543 9.31524 8.27546 9.15846 8.13099 9.08868C7.86656 8.96085 7.62555 8.7894 7.41806 8.58152C6.39378 7.55724 6.39562 5.89199 7.41806 4.86933L9.76775 2.51933C10.7961 1.49161 12.456 1.49574 13.4802 2.51933C14.508 3.54771 14.5038 5.20761 13.4802 6.23183L12.0475 7.66433C11.9559 7.75586 11.9172 7.88836 11.9474 8.01427C12.0402 8.40166 12.0899 8.7981 12.0957 9.19639C12.1006 9.52596 12.5027 9.68527 12.7359 9.4524C13.0976 9.09121 13.6933 8.49352 14.7175 7.46936C16.4279 5.7589 16.4272 2.99124 14.7174 1.28215Z"
      fill="currentColor"
    />
  </svg>
);

export default ConnectIcon;
