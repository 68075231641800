import { IconProps } from "./Icon.interface";

const DisconnectIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.50259 12.1546C9.64903 12.301 9.64903 12.5385 9.50259 12.6849L7.46956 14.7179C5.75991 16.4277 2.99238 16.428 1.28241 14.7179C-0.427375 13.0082 -0.427562 10.2408 1.28241 8.53079L3.31541 6.49776C3.46184 6.35132 3.69928 6.35132 3.84575 6.49776L4.55284 7.20485C4.69928 7.35129 4.69928 7.58873 4.55284 7.7352L2.54331 9.74473C1.47016 10.8179 1.49797 12.5031 2.56197 13.5218C3.58 14.4964 5.21159 14.5011 6.23212 13.4805L8.26516 11.4475C8.41159 11.3011 8.64903 11.3011 8.7955 11.4475L9.50259 12.1546ZM7.73484 4.5532L9.74434 2.5437C10.8177 1.47039 12.5033 1.49829 13.5219 2.56282C14.4963 3.58114 14.5003 5.21235 13.4802 6.23251L11.4472 8.26554C11.3007 8.41198 11.3007 8.64942 11.4472 8.79589L12.1543 9.50298C12.3007 9.64942 12.5382 9.64942 12.6846 9.50298L14.7176 7.46995C16.4273 5.76029 16.4277 2.99273 14.7176 1.28276C13.0079 -0.426958 10.2404 -0.427239 8.53047 1.28276L6.49744 3.31579C6.351 3.46223 6.351 3.69967 6.49744 3.84614L7.20453 4.55323C7.35094 4.69964 7.58838 4.69964 7.73484 4.5532ZM15.1831 15.8905L15.8902 15.1834C16.0366 15.037 16.0366 14.7995 15.8902 14.6531L1.34725 0.110198C1.20081 -0.0362392 0.963375 -0.0362392 0.816906 0.110198L0.109844 0.817292C-0.0365937 0.96373 -0.0365937 1.20117 0.109844 1.34764L14.6528 15.8905C14.7992 16.037 15.0366 16.037 15.1831 15.8905Z"
      fill="currentColor"
    />
  </svg>
);

export default DisconnectIcon;
