import { IconProps } from "./Icon.interface";

const ClassPublicIcon = ({ className }: IconProps) => (
  <svg className={className} viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
    <title>Public</title>
    <g fill="#cbab00">
      <path d="m800 500c0 110.46-89.543 200-200 200s-200-89.543-200-200 89.543-200 200-200 200 89.543 200 200" />
      <path d="m800 750h-400c-74.398 0-138.6 41.102-173.05 101.45 80.949 119.7 217.95 198.55 373.05 198.55s292.1-78.852 373.05-198.55c-34.5-60.348-98.652-101.45-173.05-101.45z" />
      <path d="m600 0c-331.35 0-600 268.65-600 600s268.65 600 600 600 600-268.65 600-600-268.65-600-600-600zm0 1100c-275.7 0-500-224.3-500-500s224.3-500 500-500 500 224.3 500 500-224.3 500-500 500z" />
    </g>
  </svg>
);

export default ClassPublicIcon;
