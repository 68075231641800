import { IconProps } from "./Icon.interface";

const CheckGreenIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    version="1.1"
    viewBox="0 0 1200 1200"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m879.94 334.12c-115.56 115.81-249.12 249.01-368.62 368.62l-198-168-78 91.5 240 204 42.375 35.625 39-39c127.91-128.18 279.91-279.62 408-408z"
      fill="#1a784e"
    />
  </svg>
);

export default CheckGreenIcon;
