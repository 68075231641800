import { IconProps } from "./Icon.interface";

const ClassConfidentialIcon = ({ className }: IconProps) => (
  <svg className={className} viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
    <title>Confidential</title>
    <g fill="#E56810">
      <path d="m900 800v150h-50v250h300v-250h-50v-150zm150 150h-100v-100h100z" />
      <path d="m800 500c0 110.46-89.543 200-200 200s-200-89.543-200-200 89.543-200 200-200 200 89.543 200 200" />
      <path d="m400 750c-74.398 0-138.6 41.102-173.05 101.45 80.949 119.7 217.95 198.55 373.05 198.55 26.699 0 52.648-3.1484 78.051-7.6016-1.8008-13.949-3.0508-28-3.0508-42.398 0-11.949 0.75-23.75 2-35.301 0.44922-3.8008 1.1484-7.4492 1.6992-11.199 1.1484-7.75 2.3008-15.5 3.9492-23.102 0.94922-4.25 2.1484-8.3516 3.1992-12.551 1.8008-6.9492 3.75-13.801 5.9492-20.602 1.3984-4.1992 3-8.3008 4.5508-12.398 2.5-6.5 5.1484-12.949 8-19.301 1.8516-4 3.6992-7.8984 5.6484-11.801 3.1484-6.25 6.5508-12.398 10.148-18.449 2.1016-3.6016 4.1992-7.1992 6.4492-10.648 3.9492-6.1484 8.1992-12.102 12.551-17.949 2.3008-3.0508 4.5508-6.1992 6.8984-9.1992 5-6.25 10.352-12.25 15.801-18.148 2.0508-2.25 4-4.6016 6.1484-6.8008 7.3008-7.4492 15.051-14.602 23.051-21.301 0.44922-0.35156 0.85156-0.80078 1.25-1.1992h-392.29z" />
      <path d="m600 1200c45.699 0 90.051-5.5508 132.8-15.25-19.551-28.148-34.551-59.398-44.449-93l-0.050781-0.050781c-28.699 5.1992-58.152 8.3008-88.301 8.3008-275.7 0-500-224.3-500-500s224.3-500 500-500 500 224.3 500 500c0 30.199-3.1484 59.602-8.3008 88.352l0.10156 0.050782c33.5 9.8984 64.852 24.949 92.949 44.449 9.6992-42.852 15.25-87.152 15.25-132.85 0-331.35-268.65-600-600-600s-600 268.65-600 600 268.65 600 600 600z" />
    </g>
  </svg>
);

export default ClassConfidentialIcon;
