import { IconProps } from "./Icon.interface";

const CancelIcon = ({ className }: IconProps) => (
  <svg className={className} viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m599.29 430.7 402.36-402.36 170.01 170.01-402.36 400.95 402.36 402.36-170.01 170.01-402.36-402.36-400.95 402.36-170.01-170.01 402.36-402.36-402.36-400.95 170.01-170.01z"
      fill="#D21216"
      fillRule="evenodd"
    />
  </svg>
);

export default CancelIcon;
