import { IconProps } from "./Icon.interface";

const InfoIcon2 = ({ className }: IconProps) => (
  <svg className={className} viewBox="0 0 1200 1200" width="100%">
    <g fill="#333">
      <path d="m762.34 74.246c43.672 27.344 56.906 84.918 29.562 128.59-27.348 43.672-84.918 56.906-128.59 29.562-43.672-27.348-56.91-84.918-29.562-128.59 27.344-43.672 84.918-56.91 128.59-29.562" />
      <path d="m445.54 467.09 23.281-1.5195 0.003906 0.003906c18.84-1.2305 37.117 6.6992 49.094 21.297 11.973 14.594 16.18 34.07 11.293 52.309l-130.61 487.45c-24.852 92.754 82.496 153.56 224 81.949 51.742-26.188 121.36-82.312 176.29-141.96h-0.003906c3.2422-3.5 4.6797-8.3008 3.8945-13.004-0.78516-4.707-3.7031-8.7812-7.9062-11.039-4.1992-2.2578-9.2109-2.4414-13.566-0.5-79.977 35.434-202.87 73.309-170.62-47.031l146.48-546.68-318.11 21.953c-17.27 1.1875-32.594 11.484-40.215 27.023s-6.3867 33.961 3.2422 48.344c9.6328 14.379 26.195 22.539 43.465 21.406z" />
    </g>
  </svg>
);

export default InfoIcon2;
