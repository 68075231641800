import { IconProps } from "components/icons/Icon.interface";

const ArrowIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.23484 0.109798L8.62153 0.72311C8.473 0.871641 8.47544 1.11323 8.62694 1.25873L11.1494 3.68117H0.375C0.167906 3.68117 0 3.84908 0 4.05617V4.93117C0 5.13827 0.167906 5.30617 0.375 5.30617H11.1494L8.62697 7.72861C8.47544 7.87411 8.473 8.1157 8.62156 8.26423L9.23487 8.87755C9.38131 9.02398 9.61875 9.02398 9.76522 8.87755L13.8839 4.75883C14.0304 4.61239 14.0304 4.37495 13.8839 4.22849L9.76516 0.109798C9.61872 -0.0366399 9.38128 -0.0366399 9.23484 0.109798Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowIcon;
