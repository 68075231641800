import { IconProps } from "./Icon.interface";

const RiskIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    fill="currentColor"
    width="16px"
    height="16px"
    viewBox="0 0 13000 10000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="rotate(180, 6500, 5000)" fill="currentColor" stroke="none">
      <path
        d="M6280 11374 c-14 -2 -51 -9 -83 -15 -252 -45 -510 -210 -661 -422
-24 -34 -754 -1293 -1621 -2797 -868 -1504 -2077 -3601 -2688 -4660 -611
-1059 -1125 -1959 -1142 -2000 -119 -280 -110 -601 25 -876 140 -287 381 -485
698 -572 l97 -27 5495 0 5495 0 97 27 c317 87 558 285 698 572 136 277 145
590 25 876 -17 41 -531 941 -1142 2000 -611 1059 -1820 3156 -2688 4660 -867
1504 -1597 2763 -1621 2797 -189 266 -496 428 -829 438 -71 2 -141 2 -155 -1z
m137 -1086 c6 -13 330 -576 720 -1253 390 -676 1321 -2290 2068 -3585 747
-1295 1621 -2809 1941 -3365 l583 -1010 -2665 -3 c-1465 -1 -3863 -1 -5328 0
l-2665 3 289 500 c1005 1744 2443 4238 3645 6322 765 1327 1393 2412 1396
2413 3 0 10 -10 16 -22z"
      ></path>
      <path
        d="M6275 7456 c-338 -64 -584 -357 -585 -694 0 -45 27 -233 70 -490 257
-1521 290 -1713 300 -1750 23 -85 111 -185 198 -225 67 -31 217 -31 284 0 87
40 175 140 198 225 5 18 39 209 75 423 36 215 86 507 110 650 187 1098 188
1105 182 1205 -14 258 -166 484 -402 596 -134 64 -294 86 -430 60z"
      ></path>
      <path
        d="M6313 3549 c-415 -48 -701 -459 -603 -868 62 -257 270 -466 525 -527
90 -21 240 -21 330 0 377 90 615 478 525 855 -84 351 -416 581 -777 540z"
      ></path>
    </g>
  </svg>
);

export default RiskIcon;
