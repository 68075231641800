import { IconProps } from "./Icon.interface";

const DocumentsLinesIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="190"
    height="190"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M 0 1 L 0 2 L 14 2 L 14 1 L 0 1 z M 0 5 L 0 6 L 7 6 L 7 5 L 0 5 z M 0 9 L 0 10 L 12 10 L 12 9 L 0 9 z M 0 13 L 0 14 L 9 14 L 9 13 L 0 13 z M 0 17 L 0 18 L 18 18 L 18 17 L 0 17 z " />
  </svg>
);

export default DocumentsLinesIcon;
