import { IconProps } from "components/icons/Icon.interface";

const CrossIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.48727 5.00008L9.85352 1.63383C10.0482 1.43914 10.0482 1.1232 9.85352 0.928203L9.07133 0.146016C8.87664 -0.0486719 8.5607 -0.0486719 8.3657 0.146016L4.99977 3.51258L1.63352 0.146328C1.43883 -0.0483593 1.12289 -0.0483593 0.927891 0.146328L0.146016 0.928203C-0.0486719 1.12289 -0.0486719 1.43883 0.146016 1.63383L3.51227 5.00008L0.146016 8.36633C-0.0486719 8.56102 -0.0486719 8.87695 0.146016 9.07195L0.928203 9.85414C1.12289 10.0488 1.43883 10.0488 1.63383 9.85414L4.99977 6.48758L8.36602 9.85383C8.5607 10.0485 8.87664 10.0485 9.07164 9.85383L9.85383 9.07164C10.0485 8.87695 10.0485 8.56102 9.85383 8.36602L6.48727 5.00008Z"
      fill="currentColor"
    />
  </svg>
);

export default CrossIcon;
