import { IconProps } from "./Icon.interface";

const VendorsIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 1.5H1.5C0.671875 1.5 0 2.17188 0 3V14C0 14.8281 0.671875 15.5 1.5 15.5H14.5C15.3281 15.5 16 14.8281 16 14V3C16 2.17188 15.3281 1.5 14.5 1.5ZM8 4C8 3.44687 8.44687 3 9 3C9.55313 3 10 3.44687 10 4C10 4.55312 9.55313 5 9 5C8.44687 5 8 4.55312 8 4ZM5 4C5 3.44687 5.44688 3 6 3C6.55312 3 7 3.44687 7 4C7 4.55312 6.55312 5 6 5C5.44688 5 5 4.55312 5 4ZM2 4C2 3.44687 2.44688 3 3 3C3.55312 3 4 3.44687 4 4C4 4.55312 3.55312 5 3 5C2.44688 5 2 4.55312 2 4ZM14.5 13.8125C14.5 13.9156 14.4156 14 14.3125 14H1.6875C1.58438 14 1.5 13.9156 1.5 13.8125V6.5H14.5V13.8125Z"
      fill="currentColor"
    />
  </svg>
);

export default VendorsIcon;
