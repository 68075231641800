import { IconProps } from "./Icon.interface";

const CloudEnvironmentsIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.25 13C13.25 13.4142 12.9142 13.75 12.5 13.75C12.0858 13.75 11.75 13.4142 11.75 13C11.75 12.5858 12.0858 12.25 12.5 12.25C12.9142 12.25 13.25 12.5858 13.25 13ZM10.5 12.25C10.0858 12.25 9.75 12.5858 9.75 13C9.75 13.4142 10.0858 13.75 10.5 13.75C10.9142 13.75 11.25 13.4142 11.25 13C11.25 12.5858 10.9142 12.25 10.5 12.25ZM12.5 7.75C12.0858 7.75 11.75 8.08578 11.75 8.5C11.75 8.91422 12.0858 9.25 12.5 9.25C12.9142 9.25 13.25 8.91422 13.25 8.5C13.25 8.08578 12.9142 7.75 12.5 7.75ZM10.5 7.75C10.0858 7.75 9.75 8.08578 9.75 8.5C9.75 8.91422 10.0858 9.25 10.5 9.25C10.9142 9.25 11.25 8.91422 11.25 8.5C11.25 8.08578 10.9142 7.75 10.5 7.75ZM16 5.5C16.0003 5.76337 15.9309 6.02213 15.7988 6.25C15.9309 6.47787 16.0003 6.73663 16 7V10C16.0003 10.2634 15.9309 10.5221 15.7988 10.75C15.9309 10.9779 16.0003 11.2366 16 11.5V14.5C16 15.3284 15.3284 16 14.5 16H1.5C0.671563 16 8.99314e-07 15.3284 8.99314e-07 14.5V11.5C-0.000288188 11.2366 0.0691238 10.9779 0.201188 10.75C0.0691238 10.5221 -0.000288188 10.2634 8.99314e-07 10V7C-0.000288188 6.73663 0.0691238 6.47787 0.201188 6.25C0.0691238 6.02213 -0.000288188 5.76337 8.99314e-07 5.5V2.5C8.99314e-07 1.67156 0.671563 1 1.5 1H14.5C15.3284 1 16 1.67156 16 2.5V5.5ZM1.5 5.5H14.5V2.5H1.5V5.5ZM14.5 7H1.5V10H14.5V7ZM14.5 11.5H1.5V14.5H14.5V11.5ZM12.5 4.75C12.9142 4.75 13.25 4.41422 13.25 4C13.25 3.58578 12.9142 3.25 12.5 3.25C12.0858 3.25 11.75 3.58578 11.75 4C11.75 4.41422 12.0858 4.75 12.5 4.75ZM10.5 4.75C10.9142 4.75 11.25 4.41422 11.25 4C11.25 3.58578 10.9142 3.25 10.5 3.25C10.0858 3.25 9.75 3.58578 9.75 4C9.75 4.41422 10.0858 4.75 10.5 4.75Z"
      fill="currentColor"
    />
  </svg>
);

export default CloudEnvironmentsIcon;
