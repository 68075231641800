import { IconProps } from "./Icon.interface";

const ControlIcon = ({ className }: IconProps) => (
  <svg className={className} x="0px" y="0px" viewBox="0 0 16 16" height={16}>
    <path
      fill="currentColor"
      d="M4.2,4.8c1.1,0,2-1.1,2-2.4S5.4,0,4.2,0s-2,1.1-2,2.4C2.2,3.7,3.1,4.8,4.2,4.8z M0.4,2.9h1.1c0-0.2,0-0.3,0-0.5
	c0-0.2,0-0.3,0-0.5H0.4C0.2,1.9,0,2.1,0,2.4S0.2,2.9,0.4,2.9z M9.8,8c0,1.3,0.9,2.4,2,2.4s2-1.1,2-2.4s-0.9-2.4-2-2.4
	C10.6,5.6,9.8,6.7,9.8,8z M7,2.9h8.6c0.2,0,0.4-0.2,0.4-0.5s-0.2-0.5-0.4-0.5H7C7,2,7,2.2,7,2.4C7,2.5,7,2.7,7,2.9z M0.4,8.5H9
	C9,8.3,9,8.1,9,8c0-0.2,0-0.3,0-0.5H0.4C0.2,7.5,0,7.7,0,8C0,8.2,0.2,8.5,0.4,8.5z M15.6,13.1h-7c0,0.2,0,0.3,0,0.5
	c0,0.1,0,0.2,0,0.3h7c0.2,0,0.3-0.2,0.3-0.4S15.8,13.1,15.6,13.1L15.6,13.1z M0.4,13.1c-0.2,0-0.3,0.2-0.3,0.4s0.2,0.4,0.3,0.4h2.7
	c0-0.1,0-0.2,0-0.3c0-0.2,0-0.3,0-0.5H0.4z M15.6,7.5h-1.1c0,0.2,0,0.3,0,0.5c0,0.2,0,0.3,0,0.5h1.1C15.8,8.5,16,8.2,16,8
	C16,7.7,15.8,7.5,15.6,7.5z M5.8,11.2c-1.1,0-2,1.1-2,2.4s0.9,2.4,2,2.4s2-1.1,2-2.4C7.8,12.3,7,11.2,5.8,11.2z"
    />
  </svg>
);

export default ControlIcon;
