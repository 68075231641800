import { IconProps } from "./Icon.interface";

const DescriptionIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33335 10.6673H10.6667V12.0007H5.33335V10.6673ZM5.33335 8.00065H10.6667V9.33398H5.33335V8.00065ZM9.33335 1.33398H4.00002C3.26669 1.33398 2.66669 1.93398 2.66669 2.66732V13.334C2.66669 14.0673 3.26002 14.6673 3.99335 14.6673H12C12.7334 14.6673 13.3334 14.0673 13.3334 13.334V5.33398L9.33335 1.33398ZM12 13.334H4.00002V2.66732H8.66669V6.00065H12V13.334Z"
      fill="#363D44"
    />
  </svg>
);

export default DescriptionIcon;
