import { IconProps } from "./Icon.interface";

const ProgramMapIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    viewBox="0 0 100 100"
    fill="currentColor"
    width="18"
    height="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M91.6,77.9c-3.9-4-9.8-5-14.7-2.8l-6.7-11.6c0.7-0.5,1.5-1.1,2.2-1.8c6.7-6.5,6.9-17.1,0.4-23.8S55.6,31,48.9,37.5
                    c-3.6,3.5-5.3,8.1-5.1,12.7l-13.6,1.6c-0.7-1.6-1.6-3.2-2.8-4.6c-1.3-1.5-3-2.4-4.7-3.1L24.9,26c3.4,0.1,6.7-1.1,9.3-3.6
                    c5.1-5,5.3-13.2,0.3-18.5c-5-5.1-13.2-5.3-18.5-0.3c-5.1,5-5.3,13.2-0.3,18.5c1.8,1.9,4,3.1,6.3,3.6l-2.3,17.6
                    c-3.9-0.5-7.9,0.5-11,3.5c-5.1,5-5.3,13.2-0.3,18.5c5,5.1,13.2,5.3,18.5,0.3c3.1-3,4.3-7.1,3.8-11.2L43.9,53c0.7,3.1,2,6.1,4.4,8.5
                    c5.3,5.4,13.1,6.5,19.4,3.5l6.7,11.6c-0.5,0.4-0.9,0.7-1.3,1.1c-5.1,5-5.3,13.2-0.3,18.5c5,5.1,13.2,5.3,18.5,0.3
                    C96.5,91.4,96.6,83.1,91.6,77.9z"
    ></path>
  </svg>
);

export default ProgramMapIcon;
