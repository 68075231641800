import { IconProps } from "./Icon.interface";

const ClassRestrictedIcon = ({ className }: IconProps) => (
  <svg className={className} viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
    <title>Restricted</title>
    <g fill="#9f0010">
      <path d="m600 300c-44.648 0-85.352 15.102-118.65 39.801l278.8 278.85c24.699-33.254 39.848-74.004 39.848-118.65 0-110.45-89.551-200-200-200z" />
      <path d="m552.25 693.65-145.9-145.9c17.699 71.898 74 128.15 145.9 145.9z" />
      <path d="m226.95 851.45c80.949 119.7 217.95 198.55 373.05 198.55 87.898 0 169.55-26 238.85-69.75l-230.25-230.25h-208.6c-74.398 0-138.6 41.102-173.05 101.45z" />
      <path d="m600 1200c129.6 0 249.2-41.5 347.25-111.3l-71.801-71.852c-79.051 52.453-173.7 83.152-275.45 83.152-275.7 0-500-224.3-500-500 0-101.8 30.75-196.45 83.148-275.5l-71.797-71.801c-69.852 98.051-111.35 217.7-111.35 347.3 0 331.35 268.65 600 600 600z" />
      <path d="m600 0c-129.55 0-249.15 41.449-347.2 111.3l71.852 71.852c78.996-52.453 173.6-83.152 275.35-83.152 275.7 0 500 224.3 500 500 0 101.75-30.699 196.35-83.102 275.4l71.898 71.852c69.754-98.102 111.2-217.7 111.2-347.25 0-331.35-268.65-600-600-600z" />
      <path d="m34.344 104.99 70.746-70.746 1060.6 1060.6-70.746 70.746z" />
    </g>
  </svg>
);

export default ClassRestrictedIcon;
