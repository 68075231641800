import { IconProps } from "./Icon.interface";

const PolicyIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0.666016L2 3.33268V7.33268C2 11.0327 4.56 14.4927 8 15.3327C11.44 14.4927 14 11.0327 14 7.33268V3.33268L8 0.666016ZM12.6667 7.33268C12.6667 8.56602 12.3267 9.76601 11.7467 10.806L10.78 9.83935C11.64 8.54602 11.4933 6.78602 10.3533 5.64602C9.05333 4.34602 6.94 4.34602 5.64 5.64602C4.34 6.94602 4.34 9.05935 5.64 10.3593C6.78 11.4993 8.54 11.6393 9.83333 10.786L10.98 11.9327C10.1867 12.8793 9.16 13.606 8 13.9593C5.32 13.126 3.33333 10.346 3.33333 7.33268V4.19935L8 2.12602L12.6667 4.19935V7.33268ZM8 9.99935C6.89333 9.99935 6 9.10602 6 7.99935C6 6.89268 6.89333 5.99935 8 5.99935C9.10667 5.99935 10 6.89268 10 7.99935C10 9.10602 9.10667 9.99935 8 9.99935Z"
      fill="#4F555C"
    />
  </svg>
);

export default PolicyIcon;
