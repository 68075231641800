import { IconProps } from "./Icon.interface";

const InfoIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 0.25C4.22009 0.25 0.75 3.72134 0.75 8C0.75 12.2812 4.22009 15.75 8.5 15.75C12.7799 15.75 16.25 12.2812 16.25 8C16.25 3.72134 12.7799 0.25 8.5 0.25ZM8.5 14.25C5.04588 14.25 2.25 11.4553 2.25 8C2.25 4.54703 5.046 1.75 8.5 1.75C11.9528 1.75 14.75 4.54597 14.75 8C14.75 11.4541 11.9553 14.25 8.5 14.25ZM8.5 3.6875C9.22487 3.6875 9.8125 4.27513 9.8125 5C9.8125 5.72487 9.22487 6.3125 8.5 6.3125C7.77513 6.3125 7.1875 5.72487 7.1875 5C7.1875 4.27513 7.77513 3.6875 8.5 3.6875ZM10.25 11.625C10.25 11.8321 10.0821 12 9.875 12H7.125C6.91791 12 6.75 11.8321 6.75 11.625V10.875C6.75 10.6679 6.91791 10.5 7.125 10.5H7.5V8.5H7.125C6.91791 8.5 6.75 8.33209 6.75 8.125V7.375C6.75 7.16791 6.91791 7 7.125 7H9.125C9.33209 7 9.5 7.16791 9.5 7.375V10.5H9.875C10.0821 10.5 10.25 10.6679 10.25 10.875V11.625Z"
      fill="#D21216"
    />
  </svg>
);

export default InfoIcon;
