import { IconProps } from "./Icon.interface";

const CheckMarkWhiteIcon = ({ className }: IconProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 16 16">
    <path
      d="M14.1,2.2c-2.5,2.5-5.5,5.5-8.1,8.1L1.7,6.6L0,8.6L5.3,13l0.9,0.8L7.1,13c2.8-2.8,6.1-6.1,9-9L14.1,2.2z"
      fill="#fff"
    />
  </svg>
);

export default CheckMarkWhiteIcon;
