import { IconProps } from "./Icon.interface";

const EditIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8872 1.88639L16.8872 1.88638C17.1826 1.59112 17.5831 1.42524 18.0007 1.42524C18.4184 1.42524 18.8189 1.59112 19.1143 1.88638L19.1143 1.88639L22.1143 4.88639L22.0612 4.93942L22.1143 4.8864C22.4095 5.18175 22.5754 5.58229 22.5754 5.99992C22.5754 6.41756 22.4095 6.81809 22.1143 7.11345L22.0612 7.06042L22.1143 7.11346L8.61434 20.6134L16.8872 1.88639ZM16.8872 1.88639L3.38724 15.3863C3.21408 15.5592 3.08358 15.7701 3.00609 16.0022L3.00608 16.0022L1.50609 20.5022C1.50609 20.5022 1.50608 20.5022 1.50608 20.5022C1.41337 20.7799 1.39987 21.0779 1.46711 21.3628C1.53435 21.6477 1.67966 21.9082 1.88675 22.1152C2.09383 22.3221 2.35449 22.4672 2.63946 22.5342L2.65663 22.4612M16.8872 1.88639L2.65663 22.4612M8.00009 20.9945L7.99995 20.9946L3.50004 22.4945L8.00009 20.9945ZM8.00009 20.9945C8.23159 20.9168 8.44185 20.7864 8.61426 20.6135L8.00009 20.9945ZM2.65663 22.4612L2.63946 22.5342C2.9244 22.6012 3.22237 22.5875 3.49995 22.4946L2.65663 22.4612ZM4.56628 16.5404L18.0007 3.10599L20.8947 5.99992L7.46022 19.4344L3.11932 20.8813L4.56628 16.5404Z"
      fill="#B4B6B9"
      stroke="#B4B6B9"
      strokeWidth="0.15"
    />
    <path
      d="M18.166 9.58403L18.1879 9.60599H18.219H18.2205H18.2516L18.2736 9.58399L19.3341 8.52199L19.387 8.46896L19.334 8.41596L15.584 4.66596L15.531 4.61293L15.478 4.66596L14.416 5.72796L14.3629 5.78099L14.416 5.83403L18.166 9.58403ZM4.5 14.925H4.425V15V15.75C4.425 15.9688 4.51192 16.1786 4.66664 16.3334C4.82135 16.4881 5.0312 16.575 5.25 16.575H5.925V17.25C5.925 17.4688 6.01192 17.6786 6.16664 17.8334C6.32135 17.9881 6.5312 18.075 6.75 18.075H7.425V18.75C7.425 18.9688 7.51192 19.1786 7.66664 19.3334C7.82135 19.4881 8.0312 19.575 8.25 19.575H9H9.075V19.5V17.25C9.075 17.0312 8.98808 16.8213 8.83336 16.6666C8.67865 16.5119 8.4688 16.425 8.25 16.425H7.575V15.75C7.575 15.5312 7.48808 15.3213 7.33336 15.1666C7.17865 15.0119 6.9688 14.925 6.75 14.925H4.5Z"
      fill="#B4B6B9"
      stroke="#B4B6B9"
      strokeWidth="0.15"
    />
  </svg>
);

export default EditIcon;
