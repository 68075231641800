import { IconProps } from "./Icon.interface";

const LaptopMobile = ({ className }: IconProps) => (
  <svg
    className={className}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.8 3.7H11.6V4.9H12.8V3.3C12.7994 3.08801 12.7149 2.88487 12.565 2.73497C12.4151 2.58507 12.212 2.50059 12 2.5H2.4C2.18801 2.50059 1.98487 2.58507 1.83497 2.73497C1.68507 2.88487 1.60059 3.08801 1.6 3.3V9.7H0.4C0.293913 9.7 0.192172 9.74214 0.117157 9.81716C0.0421427 9.89217 0 9.99391 0 10.1V10.5C0.000922045 10.9237 0.169481 11.3298 0.468853 11.6296C0.768225 11.9294 1.17408 12.0985 1.59775 12.1H8.8V9.7H2.8V3.7ZM15.1 5.7H10.5C10.2613 5.7 10.0324 5.79482 9.8636 5.9636C9.69482 6.13239 9.6 6.36131 9.6 6.6V14.4C9.6 14.6387 9.69482 14.8676 9.8636 15.0364C10.0324 15.2052 10.2613 15.3 10.5 15.3H15.1C15.3387 15.3 15.5676 15.2052 15.7364 15.0364C15.9052 14.8676 16 14.6387 16 14.4V6.6C16 6.36131 15.9052 6.13239 15.7364 5.9636C15.5676 5.79482 15.3387 5.7 15.1 5.7ZM14.8 14.1H10.8V6.9H14.8V14.1Z"
      fill="currentColor"
    />
  </svg>
);

export default LaptopMobile;
