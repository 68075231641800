import { IconProps } from "./Icon.interface";

const DocumentIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    viewBox="0 0 100 100"
    fill="currentColor"
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="M83.769043,16.8701172H76.762207V9.855957c0-4.0561523-3.2998047-7.355957-7.3554688-7.355957H28.3168945   c-0.6630859,0-1.2988281,0.2631836-1.7675781,0.7319336L9.6030273,20.1689453   c-0.46875,0.46875-0.7324219,1.1049805-0.7324219,1.7680664V75.777832c0,4.0561523,3.2998047,7.355957,7.3554688,7.355957   h7.0136719v7.0063477c0,4.0581055,3.296875,7.3598633,7.3496094,7.3598633H83.769043   c4.0585938,0,7.3603516-3.3017578,7.3603516-7.3598633V24.2202148   C91.1293945,20.1674805,87.8276367,16.8701172,83.769043,16.8701172z M25.8168945,11.0332642v6.0477905   c0,1.2993164-1.0566406,2.355957-2.3554688,2.355957h-6.0527954L25.8168945,11.0332642z M16.2260742,78.1337891   c-1.2988281,0-2.3554688-1.0566406-2.3554688-2.355957V24.4370117h9.5908203c4.0556641,0,7.3554688-3.2998047,7.3554688-7.355957   V7.5h38.5898438c1.2988281,0,2.3554688,1.0566406,2.3554688,2.355957v65.921875c0,1.2993164-1.0566406,2.355957-2.3554688,2.355957   H16.2260742z M86.1293945,90.1401367c0,1.3012695-1.0585938,2.3598633-2.3603516,2.3598633H30.5893555   c-1.2958984,0-2.3496094-1.0585938-2.3496094-2.3598633v-7.0063477h41.1669922c4.0556641,0,7.3554688-3.2998047,7.3554688-7.355957   V21.8701172h7.0068359c1.3017578,0,2.3603516,1.0541992,2.3603516,2.3500977V90.1401367z"></path>
      <path d="M57.1831055,21.6396484h-17.71875c-1.3808594,0-2.5,1.1191406-2.5,2.5s1.1191406,2.5,2.5,2.5h17.71875   c1.3808594,0,2.5-1.1191406,2.5-2.5S58.5639648,21.6396484,57.1831055,21.6396484z"></path>
      <path d="M57.1831055,40.3168945H28.449707c-1.3808594,0-2.5,1.1191406-2.5,2.5s1.1191406,2.5,2.5,2.5h28.7333984   c1.3808594,0,2.5-1.1191406,2.5-2.5S58.5639648,40.3168945,57.1831055,40.3168945z"></path>
      <path d="M45.2104492,58.9936523H28.449707c-1.3808594,0-2.5,1.1191406-2.5,2.5s1.1191406,2.5,2.5,2.5h16.7607422   c1.3808594,0,2.5-1.1191406,2.5-2.5S46.5913086,58.9936523,45.2104492,58.9936523z"></path>
    </g>
  </svg>
);

export default DocumentIcon;
