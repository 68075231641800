import { IconProps } from "./Icon.interface";

const ChevronIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8878 1.13779L12.3464 0.596387C12.2179 0.467871 12.0101 0.467871 11.8815 0.596387L6.92959 5.53741L1.97764 0.596387C1.84912 0.467871 1.64131 0.467871 1.51279 0.596387L0.971387 1.13779C0.842871 1.26631 0.842871 1.47412 0.971387 1.60264L6.69717 7.32843C6.82568 7.45695 7.0335 7.45695 7.16201 7.32843L12.8878 1.60264C13.0163 1.47412 13.0163 1.26631 12.8878 1.13779Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronIcon;
