import { IconProps } from "./Icon.interface";

const OrganizationIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="1 0 22 21"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m6.99927 5.00066c0-1.65722 1.34344-3.00066 3.00065-3.00066 1.65718 0 3.00068 1.34344 3.00068 3.00066 0 1.48683-1.0814 2.7211-2.5006 2.95917v1.54017h3c.8284 0 1.5.6716 1.5 1.5v1.0414c1.4195.2377 2.5013 1.4722 2.5013 2.9593 0 1.6572-1.3434 3.0006-3.0006 3.0006-1.6573 0-3.0007-1.3434-3.0007-3.0006 0-1.4867 1.0811-2.7208 2.5-2.9591v-1.0416c0-.2761-.2239-.5-.5-.5h-7c-.27614 0-.5.2239-.5.5v1.0414c1.41954.2377 2.50131 1.4722 2.50131 2.9593 0 1.6572-1.34344 3.0006-3.00065 3.0006-1.65722 0-3.00066-1.3434-3.00066-3.0006 0-1.4867 1.0811-2.7208 2.5-2.9591v-1.0416c0-.8284.67157-1.5 1.5-1.5h2.99999v-1.54015c-1.41926-.23802-2.50072-1.47231-2.50072-2.95919z"
      fill="currentColor"
    />
  </svg>
);

export default OrganizationIcon;
