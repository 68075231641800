import { IconProps } from "./Icon.interface";

const Trash = ({ className }: IconProps) => (
  <svg className={className} viewBox="0 0 1200 1200">
    <g>
      <path d="M1050 281.25a93.77 93.77 0 0 0-27.457-66.293A93.77 93.77 0 0 0 956.25 187.5h-172.5A187.505 187.505 0 0 0 481.34 79.64a187.493 187.493 0 0 0-65.09 107.86h-172.5a93.754 93.754 0 0 0-77.531 42.211 93.741 93.741 0 0 0-8.09 87.906 93.74 93.74 0 0 0 68.52 55.656l30.188 633.3a163.45 163.45 0 0 0 50.47 110.77 163.42 163.42 0 0 0 113.03 45.16h359.33a163.435 163.435 0 0 0 163.499-155.93l30-633.3A93.745 93.745 0 0 0 1050 281.25zM600 112.5a112.51 112.51 0 0 1 64.676 20.777 112.529 112.529 0 0 1 40.926 54.223h-211.2a112.499 112.499 0 0 1 105.602-75zm268.24 890.55a88.5 88.5 0 0 1-88.574 84.449h-359.33a88.498 88.498 0 0 1-88.574-84.449L301.836 375h596.25zM956.252 300h-712.5a18.752 18.752 0 0 1-16.238-28.125 18.754 18.754 0 0 1 16.238-9.375h712.5a18.752 18.752 0 0 1 16.238 28.125A18.754 18.754 0 0 1 956.252 300z" />
      <path d="M487.5 937.5a37.49 37.49 0 0 0 26.516-10.984A37.485 37.485 0 0 0 525 900V525a37.498 37.498 0 1 0-75 0v375a37.49 37.49 0 0 0 10.984 26.516A37.485 37.485 0 0 0 487.5 937.5zm225 0a37.49 37.49 0 0 0 26.516-10.984A37.485 37.485 0 0 0 750 900V525a37.498 37.498 0 1 0-75 0v375a37.49 37.49 0 0 0 10.984 26.516A37.485 37.485 0 0 0 712.5 937.5z" />
    </g>
  </svg>
);

export default Trash;
