import { DeleteOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { ButtonLoader } from "components/ButtonLoader";
import { CheckIcon, CrossIcon } from "components/icons";
import React from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  variant:
    | "primary"
    | "secondary"
    | "tertiary"
    | "modalConfirm"
    | "modalReject"
    | "neutral"
    | "link"
    | "modalDelete";
  size: "icon" | "xsmall" | "small" | "medium" | "large" | "xlarge";
  icon?: React.ReactElement;
  isLoading?: boolean;
}

const Button = ({
  children,
  icon,
  variant,
  size,
  className,
  isLoading,
  ...buttonProps
}: ButtonProps) => {
  const basicStyles = `flex   items-center justify-center rounded cursor-pointer 
    focus:outline-none focus-visible:shadow-skeldus-grey-500 focus-visible:shadow-md 
    disabled:cursor-not-allowed disabled:bg-skeldus-black-50 disabled:text-skeldus-black-150 disabled:border-skeldus-black-100`;
  const variantStyles = {
    primary:
      "hover:text-skeldus-white text-skeldus-white bg-skeldus-grey-700 hover:bg-skeldus-grey-600 active:text-skeldus-grey-100",
    secondary:
      "hover:text-skeldus-white text-skeldus-black-700 bg-skeldus-white border border-skeldus-black-200 hover:border-skeldus-black-700 hover:text-skeldus-black-600 active:bg-skeldus-black-100",
    modalReject:
      "hover:text-skeldus-white text-skeldus-black-500 border bg-skeldus-white border-skeldus-black-150 hover:bg-skeldus-black-600 hover:border-skeldus-black-600 active:text-skeldus-black-50",
    modalConfirm:
      "hover:text-skeldus-white border bg-skeldus-white hover:bg-skeldus-main-800 hover:text-skeldus-white border-skeldus-main-700 hover:border-skeldus-main-800 text-skeldus-main-750 active:text-skeldus-main-100",
    tertiary:
      "hover:text-skeldus-white text-skeldus-white bg-skeldus-main-500 hover:bg-skeldus-grey-400 transition duration-500 active:text-skeldus-grey-100",
    neutral:
      "hover:text-skeldus-white text-[#666] px-[12px] py-[8px] hover:bg-[#f5f5f5] hover:text-[#666]",
    link: "hover:underline cursor-pointer background-transparent font-semibold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150",
    modalDelete:
      "hover:text-skeldus-black-700 text-skeldus-main-700 bg-skeldus-white border border-skeldus-main-700 hover:border-skeldus-black-700 hover:text-skeldus-black-600 active:bg-skeldus-black-100",
  };

  const sizeStyles = {
    icon: "w-4 h-4",
    xsmall: "min-w-[50px] min-h-[24px] px-2 py-1 text-xs",
    small: "min-w-[70px] min-h-[34px] px-4 py-[6px]",
    medium: "text-sm min-w-[75px]  h-10 px-[24px] py-[20px]",
    large: "text-base min-w-[75px]  h-10 px-4 py-2",
    xlarge: "text-base min-w-[107px] h-14 px-8 py-4",
  };

  const iconStyles = "max-w-4 max-h-4 mr-2";

  return (
    <button
      className={classNames(basicStyles, variantStyles[variant], sizeStyles[size], className)}
      {...buttonProps}
    >
      {icon && <span className={iconStyles}>{icon}</span>}
      {variant === "modalConfirm" && (
        <span className={iconStyles}>
          <CheckIcon />
        </span>
      )}
      {variant === "modalReject" && (
        <span className={iconStyles}>
          <CrossIcon />
        </span>
      )}
      {variant === "modalDelete" && <DeleteOutlined className={iconStyles} />}
      <div className="flex items-center justify-center">
        {isLoading && <ButtonLoader />}
        <span className={`${isLoading ? "transition-margin ml-2 ease-in-out" : ""}`}>
          {children}
        </span>
      </div>
    </button>
  );
};

export default Button;
