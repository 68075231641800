import { IconProps } from "./Icon.interface";

export default function SidebarIcon({ className, color }: IconProps) {
  return (
    <svg className={className} viewBox="0 0 100 100">
      <g fill={color}>
        <polygon points="62.2,92.9 19.3,50 62.2,7.1 55.1,0 12.2,42.9 12.2,42.9 5.1,50 5.1,50 5.1,50 12.2,57.1 12.2,57.1 55.1,100 	" />
        <polygon points="44.9,42.9 37.8,50 37.8,50 37.8,50 44.9,57.1 44.9,57.1 87.8,100 94.9,92.9 52,50 94.9,7.1 87.8,0 44.9,42.9 	" />
      </g>
    </svg>
  );
}
